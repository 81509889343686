/*=========================================================================================
  File Name: estimates.js
  Description: actions requests to estimates.inc.php
==========================================================================================*/
import Vue from 'vue';

const moduleName = 'estimates';

const actions = {
  list: async ({ commit }, payload) => {
    const data = await Vue.$api.index(moduleName, 'list', payload);

    commit('set', ['masterdata', 'estimates', data.data['estimates']], {
      root: true
    });
    commit('set', ['masterdata', 'users', data.data['users']], { root: true });
    commit('set', ['masterdata', 'firms', data.data['firms']], { root: true });

    return data;
  },

  new: async ({ commit }) => {
    const data = await Vue.$api.index(moduleName, 'new');

    commit('set', ['masterdata', 'firms', data.data['firms']], { root: true });
    commit('set', ['masterdata', 'pricelists', data.data['pricelists']], {
      root: true
    });
    commit('set', ['masterdata', 'currencies', data.data['currencies']], {
      root: true
    });
    commit('set', ['masterdata', 'languages', data.data['languages']], {
      root: true
    });
    commit('set', ['masterdata', 'vats', data.data['vats']], { root: true });
    commit('set', ['masterdata', 'printdata', data.data['printdata']], {
      root: true
    });

    return data;
  },

  detail: async ({ commit }, payload) => {
    const data = await Vue.$api.index(moduleName, 'detail', payload);

    commit('set', ['masterdata', 'firms', data.data['firms']], { root: true });
    commit('set', ['masterdata', 'pricelists', data.data['pricelists']], {
      root: true
    });
    commit('set', ['masterdata', 'currencies', data.data['currencies']], {
      root: true
    });
    commit('set', ['masterdata', 'languages', data.data['languages']], {
      root: true
    });
    commit('set', ['masterdata', 'vats', data.data['vats']], { root: true });
    commit('set', ['masterdata', 'printdata', data.data['printdata']], {
      root: true
    });

    return data;
  },

  'items-all': async ({ commit }, payload) => {
    const data = await Vue.$api.get(moduleName, 'items-all', payload);

    commit('set', ['masterdata', 'jobtypes', data['jobtypes']], {
      root: true
    });
    commit('set', ['masterdata', 'units', data['units']], { root: true });

    return data;
  },

  /* TODO: smazat */
  items: async (context, payload) => {
    const data = await Vue.$api.index(moduleName, 'items', payload);

    return data.data;
  },

  'estimates-save': async (context, payload) => {
    const data = await Vue.$api.update(moduleName, 'estimates-save', payload);

    return data;
  },

  'estimates-remove': async (context, payload) => {
    const data = await Vue.$api.delete(moduleName, 'estimates-remove', payload);

    return data;
  },

  'estimates-duplicate': async (context, payload) => {
    const data = await Vue.$api.post(
      moduleName,
      'estimates-duplicate',
      payload
    );

    return data;
  },

  'estimates2-form': async (context, payload) => {
    const data = await Vue.$api.get(moduleName, 'estimates2-form', payload);

    return data;
  },

  'estimates-name-save': async (context, payload) => {
    const data = await Vue.$api.update(
      moduleName,
      'estimates-name-save',
      payload
    );

    return data.saved;
  },

  'estimates2-save': async (context, payload) => {
    const data = await Vue.$api.update(moduleName, 'estimates2-save', payload);

    return data;
  },

  'estimates2-remove': async (context, payload) => {
    const data = await Vue.$api.delete(
      moduleName,
      'estimates2-remove',
      payload
    );

    return data;
  },

  'estimates2-items-sorting': async (context, payload) => {
    const data = await Vue.$api.update(
      moduleName,
      'estimates2-items-sorting',
      payload
    );

    return data;
  },

  'estimates3-form': async ({ commit }, payload) => {
    const data = await Vue.$api.get(moduleName, 'estimates3-form', payload);

    commit('set', ['masterdata', 'pricelists', data['pricelists']], {
      root: true
    });

    return data;
  },

  'fetch-pricelists3': async (context, payload) => {
    const data = await Vue.$api.get(moduleName, 'fetch-pricelists3', payload);

    return data;
  },

  'estimates3-save': async (context, payload) => {
    const data = await Vue.$api.update(moduleName, 'estimates3-save', payload);

    return data;
  },

  'estimates3-single-save': async (context, payload) => {
    const data = await Vue.$api.update(
      moduleName,
      'estimates3-single-save',
      payload
    );

    return data;
  },

  'estimates3-multiple-save': async (context, payload) => {
    const data = await Vue.$api.update(
      moduleName,
      'estimates3-multiple-save',
      payload
    );

    return data;
  },

  'estimates3-remove': async (context, payload) => {
    const data = await Vue.$api.delete(
      moduleName,
      'estimates3-remove',
      payload
    );

    return data;
  },

  'estimates3-duplicate': async (context, payload) => {
    const data = await Vue.$api.update(
      moduleName,
      'estimates3-duplicate',
      payload
    );

    return data;
  },

  'estimates3-items-sorting': async (context, payload) => {
    const data = await Vue.$api.update(
      moduleName,
      'estimates3-items-sorting',
      payload
    );

    return data;
  },

  templates: async ({ commit }) => {
    const data = await Vue.$api.index(moduleName, 'templates');

    commit(
      'set',
      ['masterdata', 'estimateTemplates', data.data['estimate_templates']],
      { root: true }
    );

    return data;
  },

  'templates-detail': async ({ commit }, payload) => {
    const data = await Vue.$api.index(moduleName, 'templates-detail', payload);

    commit('set', ['masterdata', 'firms', data.data['firms']], { root: true });
    commit('set', ['masterdata', 'pricelists', data.data['pricelists']], {
      root: true
    });
    commit('set', ['masterdata', 'currencies', data.data['currencies']], {
      root: true
    });
    commit('set', ['masterdata', 'languages', data.data['languages']], {
      root: true
    });
    commit('set', ['masterdata', 'vats', data.data['vats']], { root: true });
    commit('set', ['masterdata', 'printdata', data.data['printdata']], {
      root: true
    });
    commit('set', ['masterdata', 'jobtypes', data.data['jobtypes']], {
      root: true
    });
    commit('set', ['masterdata', 'units', data.data['units']], { root: true });

    return data;
  },

  'templates-save': async (context, payload) => {
    const data = await Vue.$api.update(moduleName, 'templates-save', payload);

    return data;
  },

  'versions-save': async (context, payload) => {
    const data = await Vue.$api.update(moduleName, 'versions-save', payload);

    return data;
  },

  'pdf-save': async (context, payload) => {
    const data = await Vue.$api.post(moduleName, 'pdf-save', payload);

    return data.saved;
  },

  'prefill-form': async ({ commit }, payload) => {
    const data = await Vue.$api.get(moduleName, 'prefill-form', payload);

    commit('set', ['masterdata', 'currencies', data['currencies']], {
      root: true
    });

    return data;
  }
};

export default {
  namespaced: true,
  actions
};
