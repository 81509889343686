/* FUNKCE */
import Vue from 'vue';
import { tr } from '../i18n';

export default {
  getUrl(module, action, get = false) {
    let getParams = '';

    if (this.isObject(get)) {
      Object.keys(get).forEach((key) => {
        getParams += '&' + key + '=' + get[key];
      });
    } else if (get != false) {
      getParams = get;
    }

    // jestli chybi lomitko v PHP URL, pridej
    let addSlash = '';
    if (Vue.$store.getters['API_URL'].slice(-1) !== '/') {
      addSlash = '/';
    }

    return (
      Vue.$store.getters['API_URL'] +
      addSlash +
      '?m=' +
      module +
      '&act=' +
      action +
      getParams
    );
  },

  setLocalStorageAppSettings(key, val) {
    let localSettings = {};
    if (localStorage.getItem('local_settings')) {
      try {
        localSettings = JSON.parse(localStorage.getItem('local_settings'));
      } catch (e) {
        localSettings = {};
      }
    }

    localSettings[key] = val;
    localStorage.setItem('local_settings', JSON.stringify(localSettings));
  },

  createJobtypesSelect(jobtypes_list) {
    let jobtypesSelect = [];

    if (this.isArray(jobtypes_list)) {
      jobtypes_list.forEach((item) => {
        if (
          !this.arrayFind(
            jobtypesSelect,
            'value',
            item.workgroups_id + item.workgroups_name
          )
        ) {
          jobtypesSelect.push({
            value: item.workgroups_id + item.workgroups_name,
            label: item.workgroups_name,
            disabled: true,
            _classes: 'underline'
          });
        }

        jobtypesSelect.push({
          ...item,
          value: parseInt(item.id),
          label: String(item.name + ' ( ' + item.workgroups_name + ' )'),
          _classes: 'ml-2'
        });
      });
    }

    return jobtypesSelect;
  },

  // soucet hodnot klice v poli
  getArrayKeyTotal(array, key) {
    return array.reduce(
      (total, obj) => this.roundToDecimals(obj[key]) + total,
      0
    );
  },

  // volani teto fce nefunguje pro Safari D:
  getNewDate(str_date) {
    if (str_date) {
      // .replace(/\s/, 'T') OR .replace(/-/g, '/') for Safari
      let strDate = str_date.replace(/\s/, 'T');
      return new Date(strDate);
    }

    return new Date();
  },

  getArrayValuesByKey(array, key) {
    let temp = [];

    array.forEach((item) => {
      temp.push(item[key]);
    });

    return temp;
  },

  // string no diacritics
  stringNoDiacritics(text) {
    if (text) {
      return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    }

    return '';
  },

  // match lower cased values (strict = exact value)
  matchLowerCase(text = '', match_text = '', strict = false) {
    if (strict) {
      return String(text)
        .toLowerCase()
        .includes(String(match_text).toLowerCase());
    }

    return this.stringNoDiacritics(String(text).toLowerCase()).includes(
      this.stringNoDiacritics(String(match_text).toLowerCase())
    );
  },

  // split string
  splitText(text = false, split_sign = ',') {
    if (!text) {
      return [];
    }

    return text.split(split_sign);
  },

  dynamicSort(property) {
    var sortOrder = 1;
    if (property[0] === '-') {
      sortOrder = -1;
      property = property.substr(1);
    }
    return (a, b) => {
      /* next line works with strings and numbers,
       * and you may want to customize it to your needs
       */
      if (a[property] !== undefined) {
        if (this.isNumber(a[property])) {
          let result =
            this.roundToDecimals(a[property]) <
            this.roundToDecimals(b[property])
              ? -1
              : this.roundToDecimals(a[property]) >
                this.roundToDecimals(b[property])
              ? 1
              : 0;
          return result * sortOrder;
        }

        let result =
          this.stringNoDiacritics(a[property]).toLowerCase() <
          this.stringNoDiacritics(b[property]).toLowerCase()
            ? -1
            : this.stringNoDiacritics(a[property]).toLowerCase() >
              this.stringNoDiacritics(b[property]).toLowerCase()
            ? 1
            : 0;
        return result * sortOrder;
      }
    };
  },

  dynamicSortResult(arr, key, sort_flag = 'desc') {
    return arr.sort(this.dynamicSort((sort_flag == 'desc' ? '-' : '') + key));
  },

  // round number to decimal
  roundToDecimals(num, dec_len = 2) {
    let tempNum = this.numberCommaToDecimal(num);

    if (this.isNumber(tempNum) && tempNum !== '') {
      return +(Math.round(tempNum + 'e+' + dec_len) + 'e-' + dec_len); // Math.round( tempNum * (10*dec_len) / (10*dec_len) )
    }

    return 0;
  },

  // change decimal to comma
  numberDecimalToComma(data = false) {
    if (this.isNumber(data)) {
      return data
        .toString()
        .replace('.', ',') // replace comma
        .replace(/ /g, '');
    }

    return '';
  },

  // change comma to decimal
  numberCommaToDecimal(data = false) {
    if (this.isNumber(data)) {
      return data
        .toString()
        .replace(',', '.') // replace comma
        .replace(/ /g, '');
    }

    return '';
  },

  trimNumber(val, dec_len) {
    return this.numberDecimalToComma(this.roundToDecimals(val, dec_len));
  },

  // add/remove value from array
  toggleArrayValues(array, value) {
    let temp = array;

    // remove value from array
    if (array.find((item) => item == value)) {
      temp = temp.filter((item) => item != value);
    } else {
      // add value to array
      temp.push(value);
    }

    return temp;
  },

  // remove array object by index
  removeArrayByIndex(array, index) {
    if (!this.isArray(array)) {
      return false;
    }

    return array.filter((item, i) => i !== index);
  },

  // remove duplicate array objects based on key (array of objects)
  arrayUnique(array, key = 'id') {
    return array.filter(
      (value, index, self) =>
        self.findIndex((t) => t[key] === value[key]) === index
    );
  },

  // remove duplicate array item based on key (primitive array)
  arrayUniqueSimple(array) {
    return array.filter((value, index, self) => self.indexOf(value) === index);
  },

  // get duplicated array objects (returns keys or indexes)
  arrayDuplicities(array, key = 'id', returnIndexes = false) {
    /* if (returnIndexes) {
      return array
        .map(e => e[key])
        .map((e, i, final) => final.indexOf(e) !== i && i)
        .filter(obj => array[obj])
        .map((e, index) => index);
    } */

    return array
      .map((e) => e[key])
      .map((e, i, final) => final.indexOf(e) !== i && i)
      .filter((obj) => array[obj])
      .map((e) => array[e][key]);
    // return array.filter((item, index) => array.indexOf(item) !== index);
  },

  // removes key from object except defined ones (keysToKeep = [example1, example2,..])
  removeObjectKeysExcept(obj, keysToKeep) {
    Object.keys(obj).forEach((key) => {
      if (!keysToKeep.includes(key)) {
        delete obj[key];
      }
    });
  },

  // get key values from array objects (returns array values of specified key)
  arrayObjectKeyValues(array, key = 'id') {
    return array.map((e) => e[key]);
  },

  // returns new object with key value pairs between idFrom, idTo keys
  filterObjectKeysInRange(obj, idFrom, idTo) {
    if (!this.isObject(obj)) {
      return obj;
    }

    let currentIdFrom = idFrom;
    let currentIdTo = idTo;
    if (String(currentIdFrom).includes('_')) {
      currentIdFrom = String(currentIdFrom).split('_')[0];
    }
    if (this.isNumber(currentIdFrom)) {
      currentIdFrom = parseInt(currentIdFrom, 10);
    }
    if (String(currentIdTo).includes('_')) {
      currentIdTo = String(currentIdTo).split('_')[0];
    }
    if (this.isNumber(currentIdTo)) {
      currentIdTo = parseInt(currentIdTo, 10);
    }

    return Object.fromEntries(
      Object.entries(obj).filter(([key]) => {
        let currentId = key;
        if (String(currentId).includes('_')) {
          currentId = String(currentId).split('_')[0];
        }
        if (this.isNumber(currentId)) {
          currentId = parseInt(currentId, 10);
        }

        return currentId >= currentIdFrom && currentId <= currentIdTo;
      })
    );
  },

  // check if object contains key
  objectContainsKey(obj, key) {
    if (!this.isObject(obj)) {
      return false;
    }

    return Object.prototype.hasOwnProperty.call(obj, key);
  },

  // returns max value from array
  getArrayMaxValue(array) {
    if (this.isArray(array)) {
      return array.reduce(function (prev, current) {
        return prev > current ? prev : current;
      });
    }

    return null;
  },

  // returns min value from array
  getArrayMinValue(array) {
    if (this.isArray(array)) {
      return array.reduce(function (prev, current) {
        return prev < current ? prev : current;
      });
    }

    return null;
  },

  // returns object from array with max value of specified key (default ID)
  findArrayObjectWithMaxValue(array, key = 'id', type = 'number') {
    if (this.isArray(array)) {
      return array.reduce(function (prev, current) {
        if (type === 'number') {
          return Number(prev[key]) > Number(current[key]) ? prev : current;
        } else {
          return prev[key] > current[key] ? prev : current;
        }
      });
    }

    return {};
  },

  // array find based on array's key and searched value
  //  on success, returns object {}
  arrayFind(array, key = '', value = '') {
    if (!this.isArray(array)) {
      return false;
    }

    return array.find((item) => this.compareValues(item[key], value));
  },

  // array filter based on array's key and searched value
  //  optional: param 'equal' sets if array object key must be equal to searched value or not
  //    default is true
  //  on success, returns array []
  arrayFilter(array, key = '', value = '', equal = true) {
    if (!this.isArray(array)) {
      return false;
    }

    return array.filter(
      (item) => this.compareValues(item[key], value) === equal
    );
  },

  // compare values
  compareValues(value1, value2, type = 'string') {
    if (type === 'int') {
      return parseInt(value1) === parseInt(value2);
    }

    return String(value1) === String(value2);
  },

  // check if payload is string
  isString(data = false) {
    if (typeof data === 'string') {
      return true;
    }

    return false;
  },

  // check if string is empty
  isStringEmpty(data) {
    if (!data || (data !== undefined && !data.toString().trim())) {
      return true;
    }

    return false;
  },

  // check if payload is a number
  isNumber(data = false) {
    if (data !== false && data !== null && data !== undefined && data !== '') {
      let dataMod = data
        .toString()
        .replace(',', '.') // replace comma
        .replace(/ /g, ''); // replace spaces

      if (!isNaN(dataMod)) {
        return true;
      } else {
        return false;
      }
    }

    return false; // /^\d+$/.test(data)
  },

  // check if payload is array
  isArray(data = false) {
    if (data instanceof Array) {
      return true;
    }

    return false;
  },

  // check if payload is array and is empty
  isArrayEmpty(data = false) {
    if (data instanceof Array && data.length > 0) {
      return false;
    }

    return true;
  },

  // check if payload is object
  isObject(data = null) {
    if (
      typeof data === 'object' &&
      !Array.isArray(data) &&
      data !== null &&
      data !== undefined
    ) {
      return true;
    }

    return false;
  },

  // check if payload is an object and is empty
  isObjectEmpty(data = null) {
    if (
      typeof data === 'object' &&
      data !== null &&
      data !== undefined &&
      Object.keys(data).length > 0
    ) {
      return false;
    }

    return true;
  },

  changeDocumentTitle(route = { name: '', path: '' }, actionName = '') {
    let prefix = tr(route.meta.translation_key); // route.name;
    let interfix = actionName ? ' - ' + actionName : '';

    let suffix = ' - ' + Vue.$store.state.appName; // route.meta.admin ? ' - ' + Vue.$store.state.appName : ''

    document.title = prefix + interfix + suffix;
  },

  numberFormatter(val, dec_len = 2) {
    let temp = new Intl.NumberFormat('cs-CZ', {
      minimumFractionDigits: dec_len,
      maximumFractionDigits: dec_len
    });

    return temp.format(val ? val : 0);
  },

  currencyFormatter(
    val,
    {
      dec_len = 2,
      currency_code = Vue.$store.getters['DEFAULT_CURRENCY_CODE']
    } = {}
  ) {
    return this.currencyFormatterNEW(val, { dec_len, currency_code });

    /* Old approach: let temp = new Intl.NumberFormat('cs-CZ', {
      style: 'currency',
      currency: 'CZK',
      minimumFractionDigits: dec_len,
      maximumFractionDigits: dec_len
    });

    return temp.format(val ? val : 0);
    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501) */
  },

  currencyFormatterNEW(
    val,
    {
      dec_len = 2,
      currency_code = Vue.$store.getters['DEFAULT_CURRENCY_CODE']
    } = {}
  ) {
    return this.numberFormatter(val, dec_len) + ' ' + currency_code;
  },

  notificationAlert(
    message = tr('doslo_k_neocekavane_chybe_kontaktujte_spravce'),
    type = 'info',
    time_to_close = 2500,
    offset = 0,
    title = 'Info'
  ) {
    Vue.prototype.$notify({
      message: message,
      type: type,
      duration: time_to_close,
      offset: offset,
      title: title
    });

    /* Vue.$vs.notify({
      text: message,
      color: type,
      title: title,
      time: time_to_close
    }); */
  },

  createSelectOptions(
    data,
    valueKey = 'id',
    labelKey = 'name',
    radioKey = false
  ) {
    let temp = [];
    let temp_key = {};

    if (radioKey !== false) {
      temp_key = { key: radioKey };
    }

    if (Array.isArray(data) && data.length > 0) {
      data.forEach((item) => {
        let obj = {
          ...item,
          value: String(item[valueKey]),
          label: item[labelKey],
          label_no_diacritics: this.stringNoDiacritics(item[labelKey]),
          text: item[labelKey]
        };

        temp.push({ ...temp_key, ...obj });
      });
    }

    return temp;
  },

  confirmAction(message = tr('opravdu_chcete_smazat')) {
    let del = confirm(message);
    return del;
  },

  updateObjectValues(obj, new_obj, strict = true) {
    if (this.isObject(new_obj)) {
      Object.keys(new_obj).forEach((key) => {
        // pouze kdyz obj ma klic a nova hodnota neni undefined
        if (new_obj[key] === undefined) return;

        if (strict) {
          if (Object.keys(obj).includes(key)) {
            obj[key] = new_obj[key];
          }
        } else {
          Vue.set(obj, [key], new_obj[key]);
        }
      });
    }
  },

  zeroPrefix(num, digit) {
    /* let zero = '';
    for (let i = 0; i < digit; i++) {
      zero += '0';
    } 
    
    return (zero + num).slice(-digit);*/

    if (digit) {
      return String(num).padStart(2, '0');
    }

    return num;
  },

  getUserImage(uid) {
    let images = require.context(
      '@/assets/images/portrait/small/',
      false,
      /\.jpg$/
    );
    try {
      return images(`./avatar-s-${uid}.jpg`);
    } catch (error) {
      return images('./no-image2.jpg');
    }
  },

  isXMinutesPassed(dateString, x_mins) {
    if (!x_mins) return false;

    const currentDate = new Date();
    const specifiedDate = new Date(dateString);
    const fiveMinutesInMillis = x_mins * 60 * 1000; // 5 minutes in milliseconds

    // Check if the current time is x minutes or more after the specified date
    return currentDate - specifiedDate >= fiveMinutesInMillis;
  },

  dateDiffInDays(start_date, end_date, include_start_date = false) {
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;
    // Discard the time and time-zone information.
    const utc1 = Date.UTC(
      start_date.getFullYear(),
      start_date.getMonth(),
      start_date.getDate()
    );
    const utc2 = Date.UTC(
      end_date.getFullYear(),
      end_date.getMonth(),
      end_date.getDate()
    );

    const res = Math.floor((utc2 - utc1) / _MS_PER_DAY);

    if (include_start_date === true) {
      return res + 1;
    } else {
      return res;
    }
  },

  /* dateDiffInMinutes(start_date, end_date) {
        const diff = Math.abs(new Date(end_date) - new Date(start_date));

        return Math.floor((diff/1000)/60/60);
    }, */

  // eg 2202 to 22:02
  formatTimeFromNumber(number) {
    // Convert the number to a string
    let str = number.toString();

    // Pad the string with leading zeros if necessary
    while (str.length < 4) {
      str = '0' + str;
    }

    // Extract hours and minutes
    let hours = str.substring(0, 2);
    let minutes = str.substring(2);

    // Return the formatted time string
    return hours + ':' + minutes;
  },

  isValidTime(value, with_sec = false) {
    if (with_sec === true) {
      // Regular expression to match the format HH:ii:ss
      const regex = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/;

      // Test the input against the regular expression
      return regex.test(value);
    } else {
      // Regular expression to match the format HH:ii
      const regex = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;

      // Test the input against the regular expression
      return regex.test(value);
    }
  },

  // check if value is date
  isAnyDateFormat(value) {
    const dateFormatRegex = /^\d{4}-\d{1,2}-\d{1,2}$/;
    const dateTimeFormatRegex =
      /^\d{4}-\d{1,2}-\d{1,2} \d{1,2}:\d{1,2}:\d{1,2}$/;

    return dateFormatRegex.test(value) || dateTimeFormatRegex.test(value);
  },

  isDateObject(date) {
    return Object.prototype.toString.call(date) === '[object Date]';
  },

  hasTime(dateStr) {
    return /\d{2}:\d{2}(:\d{2})?/.test(dateStr);
  },

  isCurrentOrFutureYear(date) {
    if (this.isAnyDateFormat(date)) {
      return new Date(date).getFullYear() >= new Date().getFullYear();
    }

    return false;
  },

  dateToCz(
    date_def,
    {
      no_time = false,
      display_time = false,
      display_sec = false,
      no_year_logic = false,
      separator = ' '
    } = {}
  ) {
    if (!date_def) {
      return '';
    }
    if (!this.isDateObject(date_def) && !this.isAnyDateFormat(date_def)) {
      return '';
    }
    if (
      !this.isDateObject(date_def) &&
      Vue.$store.getters.IS_DEFAULT_DATE(date_def)
    ) {
      return '';
    }

    let date = null;
    if (this.isDateObject(date_def)) {
      date = date_def;
    } else {
      let dateDef = date_def.replace(/\s/, 'T');
      if (!this.hasTime(dateDef)) {
        dateDef += ' 00:00:00';
      }

      date = new Date(dateDef);
    }

    if (this.isDateObject(date)) {
      let dateOutput = '';
      let timeOutput = '';

      let day = this.zeroPrefix(date.getDate(), 2);
      let month = this.zeroPrefix(date.getMonth() + 1, 2);
      let year = date.getFullYear();

      if (no_year_logic) {
        let curYear = new Date().getFullYear();

        if (curYear !== year) {
          dateOutput = day + '.' + month + '.' + year;
        } else {
          dateOutput = day + '.' + month + '.';
        }
      } else {
        dateOutput = day + '.' + month + '.' + year;
      }

      /* feat: zobrazime cas jen kdyz je <> default */
      let temp_time =
        this.zeroPrefix(date.getHours(), 2) +
        ':' +
        this.zeroPrefix(date.getMinutes(), 2);

      if (display_sec !== false) {
        temp_time += ':' + this.zeroPrefix(date.getSeconds(), 2);
      }

      if (no_time === false) {
        if (
          display_time !== false ||
          (temp_time !== '00:00' && temp_time !== '00:00:00')
        ) {
          if (temp_time !== '00:00' && temp_time !== '00:00:00') {
            timeOutput += ', ' + temp_time;
          }
        }
      }

      /* if (display_time !== false) {
        timeOutput =
          separator +
          ' ' +
          this.zeroPrefix(date.getHours(), 2) +
          ':' +
          this.zeroPrefix(date.getMinutes(), 2);

        if (display_sec !== false) {
          timeOutput += ':' + this.zeroPrefix(date.getSeconds(), 2);
        }
      } */

      return String(dateOutput + timeOutput);
    } else {
      return '';
    }
  },

  dateFormat(date = new Date(), format = 'en', displayYear = true) {
    // return new Intl.DateTimeFormat().format(date);
    let temp = '';
    let day = this.zeroPrefix(date.getDate(), 2);
    let month = this.zeroPrefix(date.getMonth() + 1, 2);
    let year = '';

    if (displayYear) {
      if (format === 'en') {
        year = date.getFullYear() + '-';
      } else if (format === 'cs') {
        year = date.getFullYear();
      }
    }

    if (format === 'en') {
      temp = year + month + '-' + day;
    } else if (format === 'cs') {
      temp = day + '.' + month + '.' + year;
    }

    return String(temp);
  },

  dateTimeFormat(date, { has_default_time = false } = {}) {
    const yearT = date.getFullYear();
    const monthT = this.zeroPrefix(date.getMonth() + 1, 2);
    const dateT = this.zeroPrefix(date.getDate(), 2);

    let hourT = '00';
    let minT = '00';
    let secT = '00';
    if (!has_default_time) {
      if (date.getHours() !== 0) {
        hourT = this.zeroPrefix(date.getHours(), 2);
      }
      if (date.getMinutes() !== 0) {
        minT = this.zeroPrefix(date.getMinutes(), 2);
      }
      if (date.getSeconds() !== 0) {
        secT = this.zeroPrefix(date.getSeconds(), 2);
      }
    }

    return String(
      yearT + '-' + monthT + '-' + dateT + ' ' + hourT + ':' + minT + ':' + secT
    );
  },

  timeToSec(time) {
    if (!this.isStringEmpty(time) && time != 0) {
      const test = time.split(':');

      let hours = 0;
      let mins = 0;
      let secs = 0;

      if (test.length === 3) {
        hours = test[0];
        mins = test[1];
        secs = test[2];
      } else if (test.length === 2) {
        mins = test[0];
        secs = test[1];
      } else {
        secs = test[0];
      }

      return Number(hours) * 3600 + Number(mins) * 60 + Number(secs);
    }

    return 0;
  },

  hoursToSec(decimalHours) {
    if (this.isNumber(decimalHours)) {
      return Number(decimalHours) * 3600;
    }

    return 0;
  },

  secToHours(sec) {
    let seconds = Number(sec);
    return Math.floor(seconds / 3600);
  },

  secToTime(
    sec = 0,
    detailed = false,
    round_seconds = false,
    format = 'basic'
  ) {
    let seconds = Number(sec);

    if (format == 'basic') {
      if (detailed) {
        let h = Math.floor(seconds / 3600);
        let m = Math.floor((seconds % 3600) / 60);
        let s = Math.floor(seconds % 60);

        return (
          this.zeroPrefix(h, 2) +
          ':' +
          this.zeroPrefix(m, 2) +
          ':' +
          this.zeroPrefix(s, 2)
        ).replace(/,\s*$/, '');
      }

      if (round_seconds) {
        // Round seconds to the nearest minute
        const roundedMinutes = Math.round(seconds / 60);

        // Calculate hours and minutes
        const hours = Math.floor(roundedMinutes / 60);
        const minutes = roundedMinutes % 60;

        return `${this.zeroPrefix(hours, 2)}:${this.zeroPrefix(minutes, 2)}`;
      } else {
        let h = Math.floor(seconds / 3600);
        let m = Math.floor((seconds % 3600) / 60);
        let s = Math.floor(seconds % 60);

        return (
          this.zeroPrefix(h, 2) +
          ':' +
          this.zeroPrefix(m, 2) +
          ':' +
          this.zeroPrefix(s, 2)
        ).replace(/,\s*$/, '');
      }
    }

    if (detailed) {
      let d = Math.floor(seconds / (3600 * 24));
      let h = Math.floor((seconds % (3600 * 24)) / 3600);
      let m = Math.floor((seconds % 3600) / 60);
      let s = Math.floor(seconds % 60);

      let dDisplay = d > 0 ? d + (d == 1 ? ' den, ' : ' dní, ') : '';
      let hDisplay = h > 0 ? h + (h == 1 ? ' h, ' : ' h, ') : '';
      let mDisplay = m > 0 ? m + (m == 1 ? ' min, ' : ' min, ') : '';
      let sDisplay = s > 0 ? s + (s == 1 ? ' s' : ' s') : '';

      return (dDisplay + hDisplay + mDisplay + sDisplay).replace(/,\s*$/, '');
    }

    let h = Math.floor(seconds / 3600);
    let m = Math.floor((seconds % 3600) / 60);
    let s = Math.floor(seconds % 60);

    let hDisplay = h > 0 ? h + 'h' : '';
    let mDisplay =
      m > 0 ? (hDisplay ? ' ' : '') + m + (hDisplay ? 'm' : ' min') : '';
    let sDisplay = s > 0 ? s + (s == 1 ? ' s' : ' s') : '';

    if (hDisplay || mDisplay) {
      return (hDisplay + mDisplay).replace(/,\s*$/, '');
    } else {
      return sDisplay;
    }

    //return parseInt(sec/86400)+'d '+(new Date(sec%86400*1000)).toUTCString().replace(/.*(\d{2}):(\d{2}):(\d{2}).*/, "$1h $2m $3s");

    /* var sec_num = parseInt(sec, 10); // don't forget the second param
    var hours = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - hours * 3600) / 60);
    var seconds = sec_num - hours * 3600 - minutes * 60;

    return (
      this.zeroPrefix(hours, 2) +
      ':' +
      this.zeroPrefix(minutes, 2) +
      ':' +
      this.zeroPrefix(seconds, 2)
    ); */
  },

  checkFileExtension(file_path) {
    if (!file_path) return;
    //console.log(file_path)
    //if(path.includes('.jpg') || path.includes('.png'))
    const docExt = ['pdf', 'doc', 'docx', 'odt', 'xls', 'xlsx', 'txt'];
    const imgExt = ['jpg', 'png'];

    let fPath = file_path.split('.').pop();

    for (let doc of docExt) {
      if (fPath === doc) return 'document';
    }

    for (let img of imgExt) {
      if (fPath === img) return 'image';
    }

    /* docExt.some(function(doc) {
            if(file_path.split('.').pop() === doc) return 'document'
        }) 
        imgExt.some(function(img) {
            console.log(img)
            if(file_path.split('.').pop() === img) return 'image'
        })*/
    //if(file_path.split('.').pop() === 'jpg' || file_path.split('.').pop() === 'png') return 'image'
    //if(file_path.split('.').pop() === 'txt' || file_path.split('.').pop() === 'doc') return console.log('sure: ' + file_path.split('.').pop())
  },

  roundToDecimal(num, dec_len) {
    return Number(num).toFixed(dec_len);
    /* let roundedNum = Math.round((parseFloat(num) + Number.EPSILON) * 100) / 100
        let dec = roundedNum.toString().split('.')[1]
        let len = dec && dec.length > 2 ? dec.length : 2
        return roundedNum.toFixed(len) */
  },

  isEmail(email) {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  },

  browserNotification(item) {
    // Let's check if the browser supports notifications
    if (!('Notification' in window)) {
      alert(tr('tento_prohlizec_nepodporuje_notifikace'));
    }

    // Let's check whether notification permissions have already been granted
    else if (Notification.permission === 'granted') {
      // If it's okay let's create a notification
      var notification = new Notification(item.title, {
        body: item.msg,
        requireInteraction: true,
        tag: item.path_key + item.id
      });
      notification.onclick = async (e) => {
        window.focus();
        e.target.close();
        await this.handleNotifications(item);
      };
      // console.log(notification)
    }

    // Otherwise, we need to ask the user for permission
    else if (Notification.permission !== 'denied') {
      Notification.requestPermission().then(function (permission) {
        // If the user accepts, let's create a notification
        if (permission === 'granted') {
          var notification = new Notification(item.msg);
        }
      });
    }

    // At last, if the user has denied notifications, and you
    // want to be respectful there is no need to bother them any more.
  },

  async handleNotifications(notification, notification_event) {
    // Hide for now: && Vue.$store.getters['VIEW_ALL']
    if (!Vue.$router.currentRoute.path.includes('/pages')) {
      // await Vue.$router.push(Vue.$getRoute('main_page')).catch(() => {});

      await Vue.$router.replace({ query: '' }).catch(() => {});

      // nektere sekce nemaji pod-sekce (tab rozhrani), tak dame path_section do podminky
      let route = null;
      if (notification.path_section) {
        route = Vue.$getRoute(notification.path_key, notification.path_section);
      } else {
        route = Vue.$getRoute(notification.path_key);
      }

      // oprava problemu ze je uziv ve stejne sekci kam reroutuje notifikace
      let currentRoutePath = Vue.$router.currentRoute.path;
      let routePath = route.path;
      // jestli chybi lomitko, pridej
      if (currentRoutePath.slice(-1) !== '/') {
        currentRoutePath += '/';
      }
      if (routePath.slice(-1) !== '/') {
        routePath += '/';
      }
      if (currentRoutePath == routePath) {
        await Vue.$router.push('/');
      }

      await Vue.$router
        .push({
          ...route,
          params: {
            ...notification.data,
            subsection: notification.path_subsection
          },
          query: { notifi: true }
        })
        .catch(() => {});

      if (notification_event) {
        notification_event.close();
      }
      // Hide for now
      // Vue.$store.dispatch('notifications-remove', notification);
      Vue.nextTick(() => {
        Vue.$router.replace({ query: '' }).catch(() => {});
      });
    } else {
      Vue.$fnc.notificationAlert(undefined, 'error', 5000);
      /* Vue.$fnc.notificationAlert(
        'Nemáte přístup k sekci ' + notification.route + '!',
        'error'
      ); */
    }
  },

  getStatusColor(contact) {
    if (contact) {
      if (
        !Vue.$store.getters['IS_DEFAULT_DATE'](contact.last_online) &&
        this.isXMinutesPassed(contact.last_online, 1)
      ) {
        return this.statusColor('away');
      }

      return this.statusColor(contact.status);
    }

    return false;
  },

  statusColor(userStatus) {
    if (userStatus === 'online') {
      return 'success';
    } else if (userStatus === 'away') {
      return 'warning';
    } else if (userStatus === 'do not disturb') {
      return 'danger';
    } else {
      return 'grey';
    }
  },

  openFloatingWindow(url, width, height) {
    // Calculate position to center the window on the screen
    const left = (screen.width - width) / 2;
    const top = (screen.height - height) / 2;

    // Open the new window
    const newWindow = window.open(
      url,
      '_blank',
      `width=${width},height=${height},left=${left},top=${top},resizable=yes,scrollbars=yes`
    );

    // Focus the window if it's not blocked
    if (newWindow) {
      newWindow.focus();
    } else {
      // Handle cases where the window might be blocked
      alert('Pop-up window blocked. Please check your browser settings.');
    }
  },

  helloWorld() {
    return 'Hello Worlds!';
  }
};
